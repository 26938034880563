import React from "react";
//import BaseLayout from "../components/base-layout/base-layout";
//import type { HeadFC } from "gatsby"
import NotFoundPage from "./404.tsx";

export default function IndexPage() {
  return (
    <div>
      <NotFoundPage />
    </div>
  )
}

//export const Head: HeadFC = () => <title>Home Page</title>
